define('ember-cli-build-info/initializers/build-info', ['exports', 'ember'], function (exports, _ember) {
  var initialize = function initialize(application) {
    var version = _ember['default'].Object.create(application.buildInfo);
    var key = 'buildInfo';

    application.register('buildInfo:main', version, { instantiate: false, singleton: true });
    application.inject('route', key, 'buildInfo:main');
    application.inject('controller', key, 'buildInfo:main');
    application.inject('service', key, 'buildInfo:main');
  };

  exports.initialize = initialize;
  exports['default'] = {
    name: 'buildInfo',

    initialize: initialize
  };
});